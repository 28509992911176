import React from 'react';
import * as routes from '../constants/routes';
import {  Navbar, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';

class Navigation extends React.Component{
  render(){
    return(
        <header>
        <Navbar  expand="md">
        <NavbarBrand href="/"><img src="/assets/images/logo-white-dear-pink-ele4.png" alt="DearEle"/></NavbarBrand>
        <Nav className="ml-auto" navbar>

        </Nav>
        </Navbar>
      </header>
    );
  }
}
export default Navigation;
