import React from 'react';

function AllEmails(props){
    var mailids = [ "4hours", "3days", "tomorrow", "tomorrow2pm",  "wednesday", "wed", "nextweek", "nextmonth"];
    return mailids.map((item, i) => { return  <li key={i}><span className='text-highlight4'>{item}</span><span className='text-highlight6'>@dearele.com</span></li>});
};

function helphtmltext(props) {
    return (
      <div className='foo'>
        <p> DearEle.com is all about setting email reminders.Send an email to 4hours@dearele.com and DearEle will remind you with an email after 4 hours.</p>
        <div className="m-2">
            <span>You can send email like
                <div className="ml-4">
                    <AllEmails/>
                </div>            
            </span>
            <span className="mt-1">You can also add email IDs like 2days@dearele.com in BCC/CC and DearEle will follow up.</span>
        </div>        
      <h4>Contact</h4>       
        <div className="m-2">
        <p>
            For bugs, feature requests, suggestions, please email at contact@dearele.com<br/><br/> Thank you,<br/> DearEle Team
        </p>
        </div>
      </div>
    );
  };

export default helphtmltext;