import React from 'react'
import { Button, Card, CardHeader, CardBody } from 'reactstrap';
import Typed from 'typed.js';

const SPACE = ' ';
//const typedEmailStrings1 = [ "<span class='text-highlightPink'><strong>tomorrow</strong></span><span class='text-highlight6'>@dearele.com</span>" ];
const typedEmailStrings = [ '4hours', '2days', '5pm', SPACE ];                       

class EmailCompose extends React.Component {
    componentDidMount() {
    
      // If you want to pass more options as props, simply add
      // your desired props to this destructuring assignment.
      //const { strings } = this.props;
      // You can pass other options here, such as typing speed, back speed, etc.
      /*const optionsEmail1 = {
        strings: typedEmailStrings1, showCursor:false, typeSpeed: 50, backSpeed: typedBackSpeed,
            onComplete : () => { this.startOrReset( this.typedBody1 );}
      };*/
      const typedSpeed = 20;
      const typedBackSpeed = 20;   
      var emailStrs = this.getEmailType2Strings();
      const optionsEmail = {
        strings: emailStrs,  showCursor:false, typeSpeed: typedSpeed, backSpeed: typedBackSpeed,
        onComplete: () => { this.startOrReset( this.typedCc ); }
      };   
      const optionsCc = {
        strings: emailStrs,  showCursor:false, typeSpeed: typedSpeed, backSpeed: typedBackSpeed,
        onComplete: () => { this.startOrReset( this.typedBcc ); }
      }; 
      const optionsBcc = {
        strings: emailStrs,  showCursor:false, typeSpeed: typedSpeed, backSpeed: typedBackSpeed,
        onComplete: () => { this.startOrReset( this.typedEmail ); }
      };                
      this.typedEmail = new Typed(this.refEmail, optionsEmail);
      this.typedCc = new Typed(this.refCc, optionsCc);
      this.typedBcc = new Typed(this.refBcc, optionsBcc);
      this.typedCc.stop();
      this.typedBcc.stop();
    }
    getEmailType2Strings(){
        return typedEmailStrings.map( (each) => { 
            if(each === SPACE)
                return "<span class='text-highlight4'><strong>"+ each +"</strong></span>^500";;
            return "<span class='text-highlight4'><strong>"+ each +"</strong></span>@dearele.com^500";
        } );
    }
    componentWillUnmount() {
      // Make sure to destroy Typed instance on unmounting to prevent memory leaks
      this.typedEmail.destroy();
      this.typedCc.destroy();
      this.typedBcc.destroy();
    }
    startOrReset(typedRef){
        if(null == typedRef) return;
        if( typedRef.typingComplete ) {
            typedRef.reset();
        }else {
            typedRef.start();
        }
    }
    render() {
      return (
        <Card className="w-75">
            <CardHeader className="emailheader"><b>Compose Email</b></CardHeader>
            <CardBody>
                <div className="d-flex flex-row bd-highlight email-div"> 
                        <div className="align-self-baseline">To: </div>
                        <div className="align-self-baseline text-highlight6 border rounded w-100 m-1 p-1 ml-2 bg2 email-text-div"
                        ref={(emailref) => {this.refEmail = emailref;}}  style={{ whiteSpace: 'pre' }}> </div>
                </div>
                <div className="d-flex flex-row bd-highlight email-div"> 
                        <div className="align-self-baseline">Cc: </div>
                        <div className="align-self-baseline text-highlight6 border rounded w-100 m-1 p-1 ml-2 bg2 email-text-div"
                        ref={(ccref) => {this.refCc = ccref;}}  style={{ whiteSpace: 'pre' }}>  </div>
                </div>
                <div className="d-flex flex-row bd-highlight email-div"> 
                        <div className="align-self-baseline">Bcc: </div>
                        <div className="align-self-baseline text-highlight6 border rounded w-100 m-1 p-1 ml-2 bg2 email-text-div"
                        ref={(bccref) => {this.refBcc = bccref;}}  style={{ whiteSpace: 'pre' }}> </div>
                </div>                                
                <div className="d-flex flex-row bd-highlight email-div"> 
                        <div className="align-self-baseline">Subject: </div>
                        <div className="align-self-baseline border rounded w-100 m-1 p-1 ml-2 bg2 email-text-div">Client Meeting</div>
                </div>                
                
                <div className="mb-1 mt-2 p-2 border rounded  email-div bg2 email-body-div " >
                    <div></div>
                </div>

            <Button color="primary" className="mt-1 float-right glasspane2">Send</Button>
            </CardBody>
        </Card>
      );
    }
  }

  export default EmailCompose;