import React from 'react';
import { Button, Container, Row, Col } from 'reactstrap';
import EmailComposeBeta from './EmailComposeBeta';
import EmailCompose from './EmailCompose';
//import {  Redirect } from 'react-router-dom';
//import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Alert   } from 'reactstrap';
import PricingComponent from '../Pricing/PricingComponent';

class LandingPage extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      copyClipAlertVisible : false
    };

    this.onCopyClipButtonClick = this.onCopyClipButtonClick.bind(this);
    this.onDismissAlert = this.onDismissAlert.bind(this);
  }

  onCopyClipButtonClick(){
    this.setState({copyClipAlertVisible:true});
  }
  onDismissAlert(){
    this.setState({copyClipAlertVisible:false});
  }
  render() {
      return(  <div className="mb-5">
                <Container>
                  <Row>
                    <Col className="mb-5" sm="12" md={{ size: 8, offset: 2 }}>
                    <div className="main-title-div mb-1"><h1 className="text-center text-white">Easy Email Reminders </h1> </div>
                    <p className="text-center text-white mt-2 bigfont">
                      Send an email like  
                      <span className="subheading-email" style={{backgroundColor:'#e9ecef'}}>
                        <span className='text-highlight4'>4hours</span><span className='text-highlight6'>@dearele.com</span> 
                      </span>
                       and <br/>recieve reminder email after 4 hours.
                    </p>                    
                    <div className="d-flex justify-content-center mt-5">
                    {
                      (this.props.isBeta)? <EmailComposeBeta/> : <EmailCompose/>
                    }
                        
                    </div>
                    <p className="text-center text-white mt-2 bigfont">
                        Other ways to write email reminders, click
                          <Button color="warning" className="text-center ml-2" onClick={ this.props.modalHelpRef }>Help</Button>
                    </p>
                    <Alert color="info" isOpen={this.state.copyClipAlertVisible}  toggle={this.onDismissAlert} >
                          email address copied to clipboard
                    </Alert  >                    
                        
                    </Col>
                    </Row>      
                    <Row>
                      <Col>
                        <PricingComponent/>
                      </Col>
                    </Row>
                  </Container>


                </div> );
  }
}

export default LandingPage;