import React from 'react';
import { Card, CardDeck, CardHeader, CardBody,
    CardTitle, CardSubtitle, Button, Modal, ModalHeader, ModalBody, ModalFooter  } from 'reactstrap';

class  PricingComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalPricing: false
          };
      
        this.togglePricingModal = this.togglePricingModal.bind(this);  
    }
    togglePricingModal() {
        this.setState({
            modalPricing: !this.state.modalPricing
        });
      }    
    render(){
        return (
            <a name="pricing">
            <div id="pricing">
            <CardDeck className="mb-5 mt-5 text-center">
                <Card >
                    <CardHeader tag="h3">Free</CardHeader>            
                    <CardBody>
                    <CardTitle tag="h1">$0 <small className="text-muted">/ mo</small></CardTitle>
                    <CardSubtitle>Due to heavy spamming 'Free Quota' is restricted only for registered users. Please email us for registration.</CardSubtitle>
                        <ul className="list-unstyled mt-3 mb-4">
                            <li>100 reminders</li>
                        </ul>
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader tag="h3">Pro</CardHeader>            
                    <CardBody>
                    <CardTitle tag="h1">$9 <small className="text-muted">/ mo</small></CardTitle>
                    <CardSubtitle>Charged Yearly</CardSubtitle>
                        <ul className="list-unstyled mt-3 mb-4">
                            <li>250 reminders</li>
                            <li>Email support</li>
                            <li>Premium support</li>
                        </ul>
                    <Button color="primary" onClick={this.togglePricingModal}>Buy</Button>
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader tag="h3">Enterprise</CardHeader>
                    <CardBody>
                    <CardTitle tag="h1">$19 <small className="text-muted">/ mo</small></CardTitle>
                    <CardSubtitle>Charged Yearly</CardSubtitle>
                        <ul className="list-unstyled mt-3 mb-4">
                            <li>1000 reminders</li>
                            <li>Email support</li>
                            <li>Premium support</li>
                        </ul>
                    <Button color="primary" onClick={this.togglePricingModal}>Buy</Button>
                    </CardBody>
                </Card>        
            </CardDeck>
            <Modal isOpen={this.state.modalPricing} toggle={this.togglePricingModal} className={this.props.className}>
                            <ModalHeader toggle={this.togglePricingModal} tag="h3">First Month Free</ModalHeader>
                            <ModalBody className="text-center">
                                <div tag="h4">Please Email to <a href="mailto:contact@dearele.com?Subject=New%20Subscription">contact@dearele.com</a></div> 
                            </ModalBody>
                            <ModalFooter>
                            <Button color="primary" onClick={this.togglePricingModal}>Close</Button>
                            </ModalFooter>
            </Modal>       
            </div>
            </a>
        );
  }
}

export default PricingComponent;