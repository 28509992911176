import React from 'react';
import './footer.css';

class Footer extends React.Component{
  render(){
    return(
      <footer id="footer" className="footer text-center pt-3">
      <div className="container">
            <div className="row row-30">
              <div className="col">
                  <ul className="list-unstyled list-inline social text-center">
                    <li className="list-inline-item"><a href="https://www.facebook.com/dearele/"><i className="fa fa-facebook"></i></a></li>
                    <li className="list-inline-item"><a href="https://twitter.com/tealpod"><i className="fa fa-twitter"></i></a></li>
                    <li className="list-inline-item"><a href="mailto:contact@dearele.com"><i className="fa fa-envelope"></i></a></li>
                  </ul>
                </div>  
              </div>
              <div className="row row-30">          
                <div className="col">
                  <div className="pr-xl-4">
                    <p>We greatly value your privacy, no information is shared or sold. We promise to never spam you.</p>
                    <p>DearEle.com is a product of <a href="https://www.tealpod.com">Tealpod</a>.</p>
                    <p className="rights"><span>©  </span><span className="copyright-year">2018</span><span> </span><span>DearEle.com</span><span>. </span><span>All Rights Reserved.</span></p>
                  </div>
                </div>
            </div>
          </div>
      </footer>
    );
  }
}
export default Footer;
