import React from 'react'
import { Button, Card, CardHeader, CardBody } from 'reactstrap';
import Typed from 'typed.js';

//const typedEmailStrings1 = [ '<strong>remindme</strong>@dearele.com' ];
const typedEmailStrings1 = [ "<span class='text-highlightPink'><strong>remindme</strong></span><span class='text-highlight6'>@dearele.com</span>" ];

const typedBodyStrings = [ "Dear Ele,<br/> <br/>Remind me <span class='text-highlightPink'><strong>on September 7th.^500</strong></span>",
                         "Dear Ele,<br/> <br/>Remind me <span class='text-highlightPink'><strong>in 4 hours.</strong^500></span>",
                         "Dear Ele,<br/> <br/>Remind me <span class='text-highlightPink'><strong>in 2 days.</strong>^500</span>",
                         "Dear Ele,<br/> <br/>Remind me <span class='text-highlightPink'><strong>next Friday.</strong>^1000</span>",
                         ""
                       ];
                       //<strong>4hours</strong>@dearele.com', '<strong>2days</strong>@dearele.com'
const typedEmailStrings2 = [ '4hours', '2days' ];                       

class EmailComposeBeta extends React.Component {
    componentDidMount() {
    
      // If you want to pass more options as props, simply add
      // your desired props to this destructuring assignment.
      //const { strings } = this.props;
      // You can pass other options here, such as typing speed, back speed, etc.
      const typedBackSpeed = 20;
      const optionsEmail1 = {
        strings: typedEmailStrings1, showCursor:false, typeSpeed: 50, backSpeed: typedBackSpeed,
            onComplete : () => { this.startOrReset( this.typedBody1 );}
      };
      const optionsBody1 = {
        strings: typedBodyStrings,  showCursor:false, typeSpeed: 50, backSpeed: typedBackSpeed,
        onComplete: () => { this.startOrReset( this.typedEmail2 ); } 
      };      
      var emailStrs2 = this.getEmailType2Strings();
      const optionsEmail2 = {
        strings: emailStrs2,  showCursor:false, typeSpeed: 50, backSpeed: 50,
        onComplete: () => { this.startOrReset( this.typedEmail1 ); }
      };      
      // this.el refers to the <span> in the render() method
      this.typedEmail1 = new Typed(this.refEmail, optionsEmail1);
      this.typedBody1 = new Typed(this.refBodyDiv, optionsBody1);
      this.typedEmail2 = new Typed(this.refEmail, optionsEmail2);
      this.typedBody1.stop();
      this.typedEmail2.stop();
    }
    getEmailType2Strings(){
        return typedEmailStrings2.map( (each) => { return "<span class='text-highlight4'><strong>"+ each +"</strong></span>@dearele.com^500";} );
    }
    componentWillUnmount() {
      // Make sure to destroy Typed instance on unmounting to prevent memory leaks
      this.typedEmail1.destroy();
      this.typedBody1.destroy();
      this.typedEmail2.destroy();
    }
    startOrReset(typedRef){
        if(null == typedRef) return;
        if( typedRef.typingComplete ) {
            typedRef.reset();
        }else {
            typedRef.start();
        }
    }
    render() {
      return (
        <Card className="w-75">
            <CardHeader className="emailheader"><b>Compose Email Beta</b></CardHeader>
            <CardBody>
                <div className="d-flex flex-row bd-highlight email-div"> 
                        <div className="align-self-baseline">To: </div>
                        <div className="align-self-baseline text-highlight6 border rounded w-100 m-1 p-1 ml-2 bg2 email-text-div"
                        ref={(emailref) => {this.refEmail = emailref;}}  style={{ whiteSpace: 'pre' }} ></div>
                </div>
                <div className="d-flex flex-row bd-highlight email-div"> 
                        <div className="align-self-baseline">Subject: </div>
                        <div className="align-self-baseline border rounded w-100 m-1 p-1 ml-2 bg2 email-text-div">Wife's Birthday</div>
                </div>                
                {/*<Input className="mb-1" readOnly type="email" name="email" id="idEmail" placeholder="To: remindme@dearele.com" />
                <Input className="mb-1" readOnly type="text" name="text" id="idSubject" placeholder="Subject: Office Party" />
                <Input className="mb-1 mt-2 noresize" readOnly type="textarea" rows="5" name="textarea" id="idEmailBody" 
                    placeholder="Dear Ele,&#13;&#10;Remind me on September 7th." /> */}
                <div className="mb-1 mt-2 p-2 border rounded  email-div bg2 email-body-div " >
                    <div ref={(bodyDivRef) => {this.refBodyDiv = bodyDivRef;}}></div>
                </div>

            <Button color="primary" className="mt-1 float-right glasspane2">Send</Button>
            </CardBody>
        </Card>
      );
    }
  }

  export default EmailComposeBeta;