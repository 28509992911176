import React from 'react';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import * as routes from './constants/routes';
import Navigation from './Navigation';
import Footer from './Footer';
import LandingPage from './Landing';
import HelpPage from './Help';
import PricingPage from './Pricing';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import HelpHtml from './Help/helphtml';
//import './App.css';
//import './index.css';


class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      modalHelp: false
    };

    this.toggleHelpModal = this.toggleHelpModal.bind(this);    
  }
  toggleHelpModal() {
    this.setState({
      modalHelp: !this.state.modalHelp
    });
  }  
  render(){
    return(
    <Router>
      <div className="app">
        <Navigation modalHelpRef={this.toggleHelpModal}/>
        <Route exact path={routes.LANDING} component={() => <LandingPage modalHelpRef={this.toggleHelpModal}/>} /> 
        <Route exact path={routes.LANDING_BETA} component={() => <LandingPage isBeta modalHelpRef={this.toggleHelpModal}/>} /> 
        <Route exact path={routes.HELP} component={() => <HelpPage />} />      
        <Route exact path={routes.PRICING} component={() => <PricingPage />} />    

        <Footer/>
        <Modal isOpen={this.state.modalHelp} toggle={this.toggleHelpModal} className={this.props.className}>
                    <ModalHeader toggle={this.toggleHelpModal}>Help</ModalHeader>
                    <ModalBody>
                      <HelpHtml/> 
                    </ModalBody>
                    <ModalFooter>
                      <Button color="primary" onClick={this.toggleHelpModal}>Close</Button>
                    </ModalFooter>
        </Modal>        
      </div>
    </Router>
    );
  }
}

export default App;
